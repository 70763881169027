// To find your config, go to https://console.firebase.google.com/ and select your project

// Go to (top left) Settings > Project Settings > General

// scroll down to Your apps > Web Apps (if it doesnt exist, create one) > SDK setup and configuration > select npm

// your config should be visible there

export const firebaseConfig = {
  apiKey: "AIzaSyD0QDNP_hb-NNxwhhi6YeyiBL5UE8En2rc",

  authDomain: "irontimer-7fed7.firebaseapp.com",

  projectId: "irontimer-7fed7",

  storageBucket: "irontimer-7fed7.appspot.com",

  messagingSenderId: "717198760779",

  appId: "1:717198760779:web:6c79b0385f128ccb15a6ce",

  measurementId: "G-LB28QLB0Y2"
};
